<template>
  <v-container
    class="align justify"
    fluid
  >
    <!-- Boite de dialogue : reponse du serveur -->
    <v-dialog
      v-model="reponseDialog"
      width="500"
    >
      <v-card>
        <v-card-title>
          Server answer
          <v-spacer />
          <v-btn
            color="primary"
            icon
            @click="dismiss"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          {{ reponse }}
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Boite de dialogue : ajout d'un utilisateur -->
    <v-dialog
      v-model="addUser"
      width="500"
    >
      <v-card>
        <v-card-title
          class="headline primary"
          primary-title
        >
          Add User to {{ project.name }}
        </v-card-title>
        <!-- Selection du login -->
        <v-card-text>
          <v-container>
            <v-text-field
              v-model.lazy="UserName"
              label="Login"
              class="mx-4"
            />
          </v-container>
        </v-card-text>
        <v-divider />
        <!-- Action d'ajout -->
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            @click="addAdmin"
          >
            Add Admin
          </v-btn>
          <v-btn
            color="primary"
            @click="addMember"
          >
            Add Member
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Notification : alerte "projet rejete" -->
    <v-alert
      v-if="project.rejectReason"
      type="error"
    >
      This project is rejected : <br> {{ project.rejectReason }}
    </v-alert>
    <!-- Notification : alerte "no admin" -->
    <v-alert
      v-model="errorAdmin"
      type="error"
      dismissible
    >
      A project must have at least one Admin.
    </v-alert>
    <!-- Notification : alerte "login not found" -->
    <v-alert
      v-model="error"
      type="error"
      dismissible
    >
      This login doesn't exist.
    </v-alert>

    <!-- Card englobante -->
    <v-card
      :color="this.$store.state.darkTheme ? card_bg_dark : card_bg_light"
      :dark="this.$store.state.darkTheme ? true : false"
      class="my-4"
    >
      <!-- Title = short description -->
      <v-card-title class="gmiddleblue">
        Project : {{ project.description }}
        <span v-if="isMobile()">&emsp;</span>

        <!-- Bouton de sauvegarde -->
        <v-btn
          v-if="edit"
          :absolute="isMobile()?false:true"
          :right="isMobile()?false:true"
          large
          color="gred"
          @click.native="saveChange"
        >
          <v-icon left>
            fas fa-save
          </v-icon>
          Save
        </v-btn>

        <!-- Bouton d'edition -->
        <v-btn
          v-if="!edit"
          light
          :absolute="isMobile()?false:true"
          :right="isMobile()?false:true"
          large
          @click="editProject"
        >
          <v-icon left>
            fas fa-wrench
          </v-icon>
          Edit
        </v-btn>
      </v-card-title>

      <!-- Contenu de toutes les informations du projet -->
      <v-row
        class="subcard"
        :class="edit?'mt-12':''"
      >
        <!-- Main information -->
        <v-col>
          <v-card
            color="transparent"
            elevation="5"
            :min-width="isMobile()?'300px':'400px'"
          >
            <v-card-title>Main information</v-card-title>
            <v-card-text class="overflow-y-auto mb-4">
              <table class="mx-auto">
                <tr
                  v-for="(info, i) in project.mainInfos"
                  :key="i"
                  class="justify-center"
                >
                  <th
                    v-if="info.value"
                    class="text-left"
                  >
                    {{ info.name }} :
                  </th>&emsp;
                  <td
                    v-if="info.value"
                    class="text-left"
                  >
                    {{ info.value }}
                  </td>
                </tr>
                <tr
                  v-if="project.wiki"
                  class="justify-center"
                >
                  <th class="text-left">
                    Website :
                  </th>&emsp;
                  <td class="text-left">
                    <a :href="project.wiki"> Wiki page </a>
                  </td>
                </tr>
                <tr
                  class="justify-center"
                >
                  <th class="text-left">
                    Accounting :
                  </th>&emsp;
                  <td class="text-left">
                    <a         
                    :href="project.accounting"
                    target="_blank"> 
                    Accounting 
                    </a>
                  </td>
                </tr>
              </table>

              <v-alert
                v-if="btnCertifyReport && admin"
                class="mt-4"
                text
                color="gred"
              >
                <v-btn icon absolute top right>
                  <v-icon color="gred">fas fa-bell</v-icon>
                </v-btn>
                <span class="title">
                  Review time 
                </span>
                <v-checkbox
                  v-model="review_checkbox_1"
                  label="I completed the activity report on wiki page"
                  color="gred"
                  hide-details
                />
                <v-checkbox
                  :disabled="review_checkbox_1?false:true"
                  v-model="review_checkbox_2"
                  label="I certify my activity report is up-to-date"
                  color="gred"
                />
                <v-btn
                  :disabled="(review_checkbox_1 && review_checkbox_2)?false:true"
                  color="gred"
                  @click="certifyReport"
                >
                  Send my report to review
                  &thinsp;
                  <v-icon>fas fa-paper-plane</v-icon>
                </v-btn>
              </v-alert>

              <!--
                <v-alert
                  class="mt-4"
                  dense
                  text
                  type="error"
                >
                  Please, make sure you have completed your Activity Report on wiki <strong>before</strong> clicking the following button.
                </v-alert>
                <v-btn
                  color="gred"
                  @click="certifyReport"
                >
                  I certify my activity <br> report is up-to-date
                </v-btn>
              -->

              <v-btn
                v-if="btnUpdateCheck && (this.$store.state.reviewer || this.$store.state.admin) "
                color="gred"
                @click="updateCheckDate"
              >
                Update Check Date
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- List of admins of project -->
        <v-col>
          <v-card
            color="transparent"
            elevation="5"
            min-width="300px"
          >
            <v-card-title>
              Admins of project <v-btn
                v-if="addBool && (project.mainInfos.status.value != 'Pending')"
                fab
                absolute
                top
                right
                color="gred"
                small
                @click="(addUser = true)"
              >
                <v-icon>mdi-account-plus</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="overflow-y-auto mb-4">
              <table class="mx-auto">
                <tr
                  v-for="(admin, i) in project.admins"
                  :key="i"
                  class="justify-center"
                >
                  <th class="text-left">
                    {{ admin.cn }}
                  </th>&emsp;
                  <td class="text-left">
                    {{ admin.uid }}
                  </td> &emsp;
                  <td>
                    <v-btn
                      v-if="edit"
                      small
                      color="gred"
                      icon
                      @click="deleteAdmin(i)"
                    >
                      <v-icon>mdi-close-circle-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </table>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- List of members of project -->
        <v-col>
          <v-card
            color="transparent"
            elevation="5"
            min-width="300px"
          >
            <v-card-title>
              Members of project
              <v-btn
                v-if="addBool && (project.mainInfos.status.value != 'Pending')"
                fab
                absolute
                top
                right
                small
                color="gred"
                @click="(addUser = true)"
              >
                <v-icon>mdi-account-plus</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text
              class="overflow-y-auto mb-4"
              :style="showAll ? '' : 'max-height:250px'"
            >
              <table class="mx-auto">
                <tr
                  v-for="(member, i) in project.members"
                  :key="i"
                  class="justify-center"
                >
                  <th class="text-left">
                    {{ member.cn }}
                  </th>&emsp;
                  <td class="text-left">
                    {{ member.uid }}
                  </td> &emsp;
                  <td>
                    <v-btn
                      v-if="edit"
                      small
                      color="gred"
                      icon
                      @click="deleteMember(i)"
                    >
                      <v-icon>mdi-close-circle-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </table>
            </v-card-text>
            <div
              v-if="project.members.length>8"
              class="text-right"
            >
              <v-btn
                v-if="!showAll"
                icon
                x-small
                @click="switch_showAll"
              >
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
              <v-btn
                v-if="showAll"
                icon
                x-small
                @click="switch_showAll"
              >
                <v-icon>fas fa-chevron-up</v-icon>
              </v-btn>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="subcard">
        <!-- Description scientifique -->
        <v-col>
          <v-card
            color="transparent"
            elevation="5"
            min-width="300px"
          >
            <v-card-title>Scientific description</v-card-title>
            <v-card-text>
              <v-textarea
                v-model="project.scientificDescription"
                :readonly="true"
              />
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Description technique -->
        <v-col>
          <v-card
            color="transparent"
            elevation="5"
            min-width="300px"
          >
            <v-card-title>Technical description</v-card-title>
            <v-card-text>
              <v-textarea
                v-model="project.technicalDescription"
                :readonly="true"
              />
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Informations additionnelles -->
        <v-col>
          <v-card
            color="transparent"
            elevation="5"
            min-width="300px"
          >
            <v-card-title>Additional information</v-card-title>
            <v-card-text>
              <v-textarea
                v-model="project.miscDescription"
                :readonly="true"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Comments area -->
      <v-row class="subcard">
        <v-col>
          <!-- <v-card
          class="mx-3 transparent"
          elevation="5"
          :min-width="addComment ? '500px' : '300px'"
        > -->
          <v-card
            class="transparent"
            elevation="5"
            min-width="300px"
            max-width="1000px"
          >
            <v-card-title>Comments area</v-card-title>
            <v-card-text v-if="project.comments.length < 1">
              <i>No comment at this time</i>
            </v-card-text>
            <v-card-text>
              <v-list
                v-for="(comment,key) in project.comments"
                :key="key"
                dense
                color="transparent"
              >
                <v-list-item>{{ comment.username }} at {{ comment.date }} : {{ comment.comment }}</v-list-item>
              </v-list>
            </v-card-text>

            <v-card-text v-if="addComment">
              <v-row>
                <v-col
                  :cols="isMobile()?'12':'8'"
                >
                  <v-textarea
                    v-model="project.commentWaiting"
                    :color="blue"
                    label="Write a comment"
                  />
                </v-col>
                <v-col
                  :cols="isMobile()?'6':'2'"
                  align-self="end"
                  class="text-center"
                >
                  <v-btn
                    color="gmiddleblue"
                    :icon="isMobile()?false:true"
                    :block="isMobile()?true:false"
                    x-large
                    @click="pushComment(project.commentWaiting)"
                  >
                    <v-icon x-large>
                      fas fa-check
                    </v-icon>
                  </v-btn>
                </v-col>

              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <br>
    </v-card>

    <!-- Bibliography -->
    <!-- 
    <v-card
      elevation="5"
      :color="this.$store.state.darkTheme ? card_bg_dark : card_bg_light"
      :dark="this.$store.state.darkTheme ? true : false"
      class="my-4"
    >
      <v-card-title>Bibliography</v-card-title>
      <v-row class="subcard">
        <v-col>
          <v-card
            color="transparent"
            elevation="5"
            :min-width="isMobile()?'300px':'400px'"
          >
            <v-card-title>
              Results from GRICAD's clusters &emsp;
              <v-btn
                color="gmiddleblue"
                small
                :absolute="isMobile()?false:true"
                :top="isMobile()?false:true"
                :right="isMobile()?false:true"
                @click="switch_showBib_ciment()"
              >
                Show bib entry
              </v-btn>
            </v-card-title>
            <v-card-text>
              <div>
                <v-list
                  v-for="(entry,k) in project.bib_ciment_obj"
                  :key="k"
                  color="transparent"
                  :dark="$store.state.darkTheme?true:false"
                  class="my-4"
                >
                  <span>
                    {{ entry.title }}
                  </span>

                  <br>
                  <b>Authors:</b>
                  <span
                    v-for="(author,k2) in entry.author"
                    :key="k2"
                  >
                    {{ author.given }} {{ author.family }} {{ author.literal }}
                  </span>
                  <br>
                  <b>Publisher:</b> {{ entry.publisher }}
                  <br>
                  <b>Journal:</b> {{ entry.journal }}
                  <br>
                  <v-card-text
                    v-if="showBib_ciment"
                  >
                    {{
                      project.bib_ciment_str[k]
                    }}
                  </v-card-text>
                </v-list>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card
            color="transparent"
            elevation="5"
            :min-width="isMobile()?'300px':'500px'"
          >
            <v-card-title>
              Scientific publications &emsp;
              <v-btn
                color="gmiddleblue"
                small
                :absolute="isMobile()?false:true"
                :top="isMobile()?false:true"
                :right="isMobile()?false:true"
                @click="switch_showBib_scientific()"
              >
                Show bib entry
              </v-btn>
            </v-card-title>
            <v-card-text>
              <div>
                <v-list
                  v-for="(entry,k) in project.bib_scientific_obj"
                  :key="k"
                  color="transparent"
                  :dark="$store.state.darkTheme?true:false"
                  class="my-4"
                >
                  <span>
                    {{ entry.title }}
                  </span>

                  <br>
                  <b>Authors:</b>
                  <span
                    v-for="(author,k2) in entry.author"
                    :key="k2"
                  >
                    {{ author.given }} {{ author.family }} {{ author.literal }}
                  </span>
                  <br>
                  <b>Publisher:</b> {{ entry.publisher }}
                  <br>
                  <b>Journal:</b> {{ entry.journal }}
                  <br>
                  <v-card-text
                    v-if="showBib_scientific"
                  >
                    {{
                      project.bib_scientific_str[k]
                    }}
                  </v-card-text>
                </v-list>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    -->
    <br><br><br>
  </v-container>
</template>

<script>
  import config from '@/config'
  import axios from 'axios'
  import checkToken from '../store/checkToken'
  var parse = require('bibtex-parser')
  const Cite = require('citation-js')
  import {parseBibFile} from "bibtex";


  function formatDate (date) {
    var d = new Date(date)
    var month = '' + (d.getMonth() + 1)
    var day = '' + d.getDate()
    var year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [day, month, year].join('/')
  }

  export default {
    data: () => ({
      card_bg_dark: config.colors.card_bg_dark,
      card_bg_light: config.colors.card_bg_light,
      blue: config.colors.gricad_oceanblue,
      red: config.colors.gricad_red_dark1,
      addUser: false,
      showAll: false,
      addComment: false,
      addBiblio: false,
      btnCertifyReport: false,
      btnUpdateCheck: false,
      project: {
        name: '',
        description: '',
        mainInfos: {
          name: { name: 'Project name', value: '' },
          status: { name: 'Status of project', value: '' },
          lab: { name: 'Laboratory', value: '' },
          cpuTime: { name: 'Estimated CPU Time', value: '' },
          begin: { name: 'Start of project', value: '' },
          end: { name: 'End of project', value: '' },
          review: { name: 'Annual check date', value: '' },
          validate: { name: 'Checked by ', value: '' },
          projectType: { name: 'Project Type ', value: '' },
        },
        validateReport: [],
        admins: [],
        members: [],
        miscDescription: '',
        // accounting: 'https://perseus.ujf-grenoble.fr/project/pr-admin/accounting',
        wiki: 'https://perseus-wiki.univ-grenoble-alpes.fr/en/',
        accounting: '',
        bibliography: [
          {
            title: 'Scientific publications',
            link:
              '',
          },
          {
            title: "Publications containing results obtained on CIMENT's clusters",
            link:
              '',
          },
        ],
        status: '',
        laboratory: '',
        creationDate: '',
        validityDate: '',
        checkDate: '',
        estimatedCpuTime: '',
        scientificDescription: '',
        technicalDescription: '',
        comments: [],
        commentWaiting: '',
        rejectReason: '',
        bib_ciment_obj: [],
        bib_ciment_str: [],
        bib_scientific_obj: [],
        bib_scientific_str: [],
      },
      UserName: '',
      edit: false,
      addBool: false,
      admin: false,
      error: false,
      showBib_ciment: false,
      showBib_scientific: false,
      errorAdmin: false,
      reponse: '',
      reponseDialog: false,
      review_checkbox_1: false,
      review_checkbox_2: false,
    }),

    created () {
      var token = $cookies.get('token')
      var validToken = checkToken(token)
      if (!validToken) {
        this.$router.push('/login')
      } else {
        this.update()
      }
    },
    methods: {
      isMobile () {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true
        } else {
          return false
        }
      },
      dismiss () {
        this.reponse = ''
        this.reponseDialog = false
      },
      switch_showAll () {
        this.showAll = !this.showAll
      },
      editProject () {
        var token = $cookies.get('token')
        var validToken = checkToken(token)
        if (!validToken) {
          this.$router.push('/login')
        } else {
          var admins = this.project.admins
          admins.forEach(admin => {
            if (this.$store.state.account.login === admin.uid) {
              this.edit = true
            }
          })
          if (this.$store.state.admin || this.$store.state.reviewer) {
            this.edit = true
          }
        }
      },
      saveChange () {
        var token = $cookies.get('token')
        var validToken = checkToken(token)
        if (!validToken) {
          this.$router.push('/login')
        } else {
          axios.post('/project/' + this.project.name + '/set-technicalDescription', {
            technicalDescription: this.project.technicalDescription,
          }).then(response => {
          })
          axios.post('/project/' + this.project.name + '/set-scientificDescription', {
            scientificDescription: this.project.scientificDescription,
          }).then(response => {
          })
          var json = { miscDescription: this.project.miscDescription }
          axios.post('/project/' + this.project.name + '/set-miscDescription', {
            miscDescription: this.project.miscDescription,
          }).then(response => {
          })
          this.edit = false
        }
      },
      addMember () {
        var token = $cookies.get('token')
        var validToken = checkToken(token)
        if (!validToken) {
          this.$router.push('/login')
        } else {
          axios.get('/project/' + this.project.name + '/add-member/' + this.UserName).then(response => {
            if (String(response.data).includes('ERROR')) {
              this.reponse = response.data
              this.reponseDialog = true
              this.UserName = ''
              this.addUser = false
            } else {
              axios.get('/username/' + this.UserName + '/get-cn').then(response => {
                this.project.members.push({
                  uid: this.UserName,
                  cn: response.data,
                })
                this.UserName = ''
                this.addUser = false
              })
            }
          })
        }
      },
      addAdmin () {
        var token = $cookies.get('token')
        var validToken = checkToken(token)
        if (!validToken) {
          this.$router.push('/login')
        } else {
          axios.get('/project/' + this.project.name + '/add-admin/' + this.UserName).then(response => {
            if (String(response.data).includes('ERROR')) {
              this.reponse = response.data
              this.reponseDialog = true
              this.addUser = false
              this.UserName = ''
            } else {
              axios.get('/username/' + this.UserName + '/get-cn').then(response => {
                this.project.admins.push({
                  uid: this.UserName,
                  cn: response.data,
                })
                this.addUser = false
                this.UserName = ''
              })
            }
          })
        }
      },
      deleteMember (index) {
        var token = $cookies.get('token')
        var validToken = checkToken(token)
        if (!validToken) {
          this.$router.push('/login')
        } else {
          axios.get('/project/' + this.project.name + '/delete-member/' + this.project.members[index].uid).then(response => {
            if (String(response.data).includes('ERROR')) {
              this.reponse = response.data
              this.reponseDialog = true
            } else {
              this.project.members.splice(index, 1)
            }
          })
          this.UserName = ''
        }
      },
      deleteAdmin (index) {
        var token = $cookies.get('token')
        var validToken = checkToken(token)
        if (!validToken) {
          this.$router.push('/login')
        } else {
          axios.get('/project/' + this.project.name + '/delete-admin/' + this.project.admins[index].uid).then(response => {
            if (String(response.data).includes('ERROR')) {
              this.reponse = response.data
              this.reponseDialog = true
            } else {
              this.project.admins.splice(index, 1)
            }
          })
        }
      },
      switch_addComment () {
        this.addComment = !this.addComment
      },
      switch_showBib_ciment () {
        this.showBib_ciment = !this.showBib_ciment
      },
      switch_showBib_scientific () {
        this.showBib_scientific = !this.showBib_scientific
      },
      pushComment (localComment) {
        var token = $cookies.get('token')
        var validToken = checkToken(token)
        if (!validToken) {
          this.$router.push('/login')
        } else {
          if (localComment != null) {
            var localDate = new Date()
            const year = localDate.getFullYear()
            const date = localDate.getDate()
            const month = localDate.getMonth()
            const hour = localDate.getHours()
            const minutes = localDate.getMinutes()
            const seconde = localDate.getSeconds()
            localDate = year + '-' + month + '-' + date + ' ' + hour + ':' + minutes + ':' + seconde
            var comment = {
              username: this.$store.state.account.login,
              date: localDate,
              comment: localComment,
            }
            this.project.comments.push({
              username: this.$store.state.account.login,
              date: localDate,
              comment: localComment,
            })
            axios
              .get('/project/' + this.project.name + '/get-perseusData')
              .then(response => {
                var perseusData = response.data
                if (perseusData == null) {
                  perseusData = {}
                }
                var previousComments = []
                if (perseusData.comments != null) {
                  previousComments = perseusData.comments
                }
                comment.private = 'NO'
                comment.comment = btoa(localComment)
                if (previousComments.length != null) {
                  previousComments.push(comment)
                } else {
                  previousComments = comment[0]
                }
                perseusData.comments = previousComments
                axios.post('/project/' + this.project.name + '/set-perseusData', JSON.stringify(perseusData))
              })
            axios.get('project/' + this.project.name + '/notify-newComment')
            this.project.commentWaiting = ''
          }
        }
      },
      switch_addBiblio () {
        this.addBiblio = !this.addBiblio
      },
      update () {
        var projectName = this.$route.path.slice(21)
        this.project.name = projectName
        var nowTs = Math.round(Date.now() / 1000)
        axios
          .get('/project/' + projectName)
          .then(response => {
            var localProject = response.data
            if (String(localProject).includes('ERROR')) {
              this.$router.push('/error/notfound')
            }
            this.project.description = ''
            if (localProject.description != null) {
              this.project.description = localProject.description[0]
            }
            if (localProject.projectType != null) {
              this.project.mainInfos.projectType.value = localProject.projectType[0]
            }
            this.project.mainInfos.name.value = projectName
            axios.get('/project/' + projectName + '/get-projectStatus')
              .then(response => {
                this.project.mainInfos.status.value = response.data
              })
            axios
              .get('/project/' + projectName + '/get-lab')
              .then(response => {
                this.project.mainInfos.lab.value = response.data.slice(2)
              })
            this.project.mainInfos.begin.value = formatDate(
              Number(localProject.creationDate[0] * 1000)
            )
            if (localProject.validityDate != null) {
              this.project.mainInfos.end.value = formatDate(Number(localProject.validityDate[0] * 1000))
            }
            if (localProject.checkDate != null) {
              this.project.mainInfos.review.value = formatDate(Number(localProject.checkDate[0] * 1000))
              if (localProject.checkDate[0] - nowTs < 2 * 30 * 24 * 3600) {
                console.log('certify :' + String(localProject.checkDate[0] - nowTs < 2 * 30 * 24 * 3600))
                this.btnCertifyReport = true
              }
            }
            if (localProject.estimatedCpuTime != null) {
              this.project.mainInfos.cpuTime.value = localProject.estimatedCpuTime[0]
            }
            localProject.adminUid.forEach(admin => {
              if(admin == this.$store.state.account.login) {
                this.addBool = true
              }
              axios
                .get('/username/' + admin + '/get-cn')
                .then(response => {
                  this.project.admins.push({
                    uid: admin,
                    cn: response.data,
                  })
                })
              if (this.$store.state.account.login === admin) {
                this.admin = true
              }
            })
            if (localProject.memberUid != null) {
              localProject.memberUid.forEach(member => {
                axios
                  .get('/username/' + member + '/get-cn')
                  .then(response => {
                    this.project.members.push({
                      uid: member,
                      cn: response.data,
                    })
                  })
              })
            }
            this.project.wiki = this.project.wiki + projectName + '/Description'
            this.project.accounting = 'https://gricad-dashboards.univ-grenoble-alpes.fr/d/8nXIw5XMz/oar-clusters-project-stats?orgId=1&var-Cluster=All&var-Queue=besteffort&var-Queue=default&var-Project=' + this.project.name.substring(3) + '&var-User=All'
            this.project.scientificDescription = this.b64DecodeUnicode(localProject.scientificDescription[0])
            this.project.technicalDescription = this.b64DecodeUnicode(localProject.technicalDescription[0])
            if (localProject.miscDescription != null) {
              this.project.miscDescription = this.b64DecodeUnicode(localProject.miscDescription[0])
            }
            if (localProject.perseusData != null) {
              var perseusData = JSON.parse(localProject.perseusData)

              var localComments = perseusData.comments
              if (localComments != null) {
                for (var index = 0; index < localComments.length; index++) {
                  var comment = localComments[index]
                  this.project.comments.push({
                    username: comment.username,
                    date: comment.date,
                    comment: this.b64DecodeUnicode(comment.comment),
                  })
                }
              }
              if (perseusData.reject != null) {
                this.project.rejectReason = perseusData.reject.username + ' at ' + perseusData.reject.date + ' : ' + this.b64DecodeUnicode(perseusData.reject.motif)
              }
              if (perseusData.validate_by != null) {
                var localValidate = perseusData.validate_by
                this.project.mainInfos.validate.value = localValidate.username + ' at ' + formatDate(Number(localValidate.date * 1000))
              }
              if (perseusData.validate_report != null) {
                var lastDemand = perseusData.validate_report[perseusData.validate_report.length - 1]
                console.log('update:' + String(Math.abs(lastDemand.check_date - nowTs) < 2 * 30 * 24 * 3600))
                if (Math.abs(lastDemand.check_date - nowTs) < 2 * 30 * 24 * 3600 && localProject.checkDate[0] - nowTs < 2 * 30 * 24 * 3600) {
                  this.btnCertifyReport = false
                  this.btnUpdateCheck = true
                }
                if (localProject.checkDate[0] < nowTs) {
                  this.btnUpdateCheck = true
                }
              }
              if (perseusData.bibciment) {
                var bibsCiment = perseusData.bibciment
                var resultCiment = this.b64DecodeUnicode(bibsCiment)
                var localCiment = Cite.input(resultCiment)
                localCiment.forEach(entry => {
                  this.project.bib_ciment_obj.push(entry)
                })
                var separetedCiment = resultCiment.split('@')
                for (let i = 1; i < separetedCiment.length; i++) {
                  var strCiment = '@' + separetedCiment[i]
                  // les blocs de biblio etant ensemble je les separe avec le @ pour les reformer en array
                  this.project.bib_ciment_str.push(strCiment)
                }
              }
              if (perseusData.bibscientific) {
                var bibsScientific = perseusData.bibscientific
                var resultScientific = this.b64DecodeUnicode(bibsScientific)
                var localScientific = parseBibFile(resultScientific)
                localScientific.content.forEach(entry => {
                  this.project.bib_scientific_obj.push(entry)
                })
                // les blocs de biblio etant ensemble je les separe avec le @ pour les reformer en array
                var separetedScientific = resultScientific.split('@')
                for (let i = 1; i < separetedScientific.length; i++) {
                  var strScientific = '@' + separetedScientific[i]
                  this.project.bib_scientific_str.push(strScientific)
                }
              }
            }
            this.project.bibliography[0].link = 'https://perseus.ujf-grenoble.fr/project/' + projectName + '/biblio/bibscientific'
            this.project.bibliography[1].link = 'https://perseus.ujf-grenoble.fr/project/' + projectName + '/biblio/bibciment'
          })
      },
      certifyReport () {
        axios.get('/project/' + this.project.name + '/certify-report/' + this.$store.state.account.login).then(response => {
           this.reponse = response.data
          this.reponseDialog = true
        })
      },
      updateCheckDate () {
        axios.get('/project/' + this.project.name + '/update-checkDate/by/' + this.$store.state.account.login).then(response => {
           this.reponse = response.data
          this.reponseDialog = true
        })
      },
      b64DecodeUnicode (str) {
        return decodeURIComponent(escape(window.atob( str )));
      },
    },
  }
</script>

<style lang="scss">
$marge: 0px;

.subcard {
  margin-top: $marge;
  margin-bottom: $marge;
  margin-right: $marge;
  margin-left: $marge;
}

</style>
